@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.style-1 {
  -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0)
    rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  /* opacity: 0; */
}

@keyframes updown {
  0% {
    transform: translateY(-10%);
  }

  50% {
    transform: translateY(1%);
  }

  100% {
    transform: translateY(-10%);
  }
}

.shape {
  /* position: relative; */
  animation: updown 4s ease-in-out infinite;
  transform-style: preserve-3d;
  will-change: transform;
}

.left-right-animation {
  animation: drift 35s 10s linear infinite backwards;
}

@keyframes drift {
  from {
    transform: translateX(-255px);
  }
  to {
    transform: translatex(1350px);
  }
}

.animation {
  flex: 1;
}

.skeleton {
  background-color: rgba(240, 240, 240, 1);
  background-image: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1),
    rgba(240, 240, 240, 1),
    rgba(255, 255, 255, 1),
    rgba(240, 240, 240, 1),
    rgba(240, 240, 240, 1)
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 2px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: skeleton 500ms ease-in-out infinite;

  @keyframes skeleton {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
}

.scrollmagic-pin-spacer {
  padding-bottom: 0% !important;
}

.scrollmagic-pin-spacer span {
  border-radius: 40px !important;
  min-width: 50vw;
}

.bg-secondary-1 {
  /* background: url('./assets//img//new/jaipur.png'), #c7deff !important; */
}

.header-nav-list-item.middle {
  font-weight: 500;
}

.insetShadow {
  box-shadow:
    inset 2px -1px 2px rgba(44, 42, 55, 0.2),
    1px 1px 2px rgba(44, 42, 55, 0.08);
}

.boxShadow {
  box-shadow: 0 2px 12px rgba(20, 20, 43, 0.08);
}

.integration-card---inside {
  padding: 43px 40px 44px !important;
}

.buttonImage {
  transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.buttonImage:hover {
  /* background: linear-gradient(45deg, rgba(109, 100, 100, 0.75), #1b1633),
    url("./assets//img/buttonBack.png");
  background-size: cover;
  color: black; */
}

.active {
  background-color: #bdbdff;
}

/* 
** Clients Section CSS
*/

.vp-sponsor {
  border-radius: 16px;
  overflow: hidden;
}

.vp-sponsor.aside {
  border-radius: 12px;
}

.vp-sponsor-section + .vp-sponsor-section {
  margin-top: 4px;
}

.vp-sponsor-tier {
  margin-bottom: 4px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 24px;
  width: 100%;
  font-weight: 600;
  color: #3c3c43c7;
  background-color: #f6f6f7;
}

.vp-sponsor.normal .vp-sponsor-tier {
  padding: 13px 0 11px;
  font-size: 14px;
}

.vp-sponsor.aside .vp-sponsor-tier {
  padding: 9px 0 7px;
  font-size: 12px;
}

.vp-sponsor-grid + .vp-sponsor-tier {
  margin-top: 4px;
}

.vp-sponsor-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.vp-sponsor-grid.xmini .vp-sponsor-grid-link {
  height: 64px;
}

.vp-sponsor-grid.xmini .vp-sponsor-grid-image {
  max-width: 64px;
  max-height: 22px;
}

.vp-sponsor-grid.mini .vp-sponsor-grid-link {
  height: 72px;
}

.vp-sponsor-grid.mini .vp-sponsor-grid-image {
  max-width: 96px;
  max-height: 24px;
}

.vp-sponsor-grid.small .vp-sponsor-grid-link {
  height: 96px;
}

.vp-sponsor-grid.small .vp-sponsor-grid-image {
  max-width: 96px;
  max-height: 24px;
}

.vp-sponsor-grid.medium .vp-sponsor-grid-link {
  height: 112px;
}

.vp-sponsor-grid.medium .vp-sponsor-grid-image {
  max-width: 120px;
  max-height: 36px;
}

.vp-sponsor-grid.big .vp-sponsor-grid-link {
  height: 184px;
}

.vp-sponsor-grid.big .vp-sponsor-grid-image {
  max-width: 192px;
  max-height: 56px;
}

.vp-sponsor-grid[data-vp-grid="2"] .vp-sponsor-grid-item {
  width: calc((100% - 4px) / 2);
}

.vp-sponsor-grid[data-vp-grid="3"] .vp-sponsor-grid-item {
  width: calc((100% - 4px * 2) / 3);
}

.vp-sponsor-grid[data-vp-grid="4"] .vp-sponsor-grid-item {
  width: calc((100% - 12px) / 4);
}

.vp-sponsor-grid[data-vp-grid="5"] .vp-sponsor-grid-item {
  width: calc((100% - 16px) / 5);
}

.vp-sponsor-grid[data-vp-grid="6"] .vp-sponsor-grid-item {
  width: calc((100% - 4px * 5) / 6);
}

.widthLg {
  width: calc((100% - 12px) / 4);
}

.widthXs {
  width: calc((100% - 4px) / 2);
}

.vp-sponsor-grid-item {
  flex-shrink: 0;
  width: 100%;
  background-color: #f6f6f7;
  transition: background-color 0.25s;
  border-radius: 32px;
}

.vp-sponsor-grid-item:hover {
  background-color: #8e96aa24;
}

.vp-sponsor-grid-item:hover .vp-sponsor-grid-image {
  filter: grayscale(0) invert(0);
}

.vp-sponsor-grid-item.empty:hover {
  background-color: #f6f6f7;
}

.dark .vp-sponsor-grid-item:hover {
  background-color: --vp-c-white;
}

.dark .vp-sponsor-grid-item.empty:hover {
  background-color: #f6f6f7;
}

.vp-sponsor-grid-link {
  display: flex;
}

.vp-sponsor-grid-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.vp-sponsor-grid-image {
  max-width: 100%;
  filter: grayscale(1);
  transition: filter 0.25s;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
}

.megamenu:hover .w-dropdown-list {
  display: block !important;
}

@media screen and (max-width: 650px) {
  .vp-sponsor-grid-item {
    width: 49% !important;
  }
}
